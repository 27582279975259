<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3 hidden'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='dataItem'></project-employee-left-panel>
                </div>

                <span>
					<hr>
				</span>

                <span>

                <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Maaş Listesi</h3>

					<p class="text-lg">
                        Bu alanda personelin projede aldığı maaşların tam listesini görüntülüyorsunuz.<br>
                        <br>
					</p>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-12 lg:col-12'>

            <div class='card p-fluid p-6 h-full flex flex-column'>
                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>
                <div class='col-12 p-0'>

                    <DataTable ref='dt' :value='dataList' dataKey='id' :paginator='true' :rows='100' :filters='filters' :loading='loadingIndicator' paginatorTemplate='FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown' :rowsPerPageOptions='[5,10,25,50,100,200,300]' currentPageReportTemplate='{first} Sayfada {last} Kayıt Gösteriliyor. Toplam Kayıt: {totalRecords}' responsiveLayout='scroll' showGridlines stripedRows>

                        <template #header>
                            <div class='flex flex-column md:flex-row md:justify-content-between md:align-items-center'>
                                <h5 class='mb-1 mt-1'>Maaş Listesi</h5>
                            </div>
                        </template>

                        <template #loading>
                            Maaşlar Hesaplanıyor. Lütfen Bekleyin.
                        </template>

                        <template #empty>
                            Seçili Projede Personele Ait Bordro Bulunamadı.
                        </template>

                        <Column field='employee.name' header='Ad' :sortable='true'>
                            <template #body='slotProps'>
                                {{ slotProps.data.employee.name }}
                            </template>
                        </Column>

                        <Column field='employee.surName' header='Soyad' :sortable='true'>
                            <template #body='slotProps'>
                                {{ slotProps.data.employee.surName }}
                            </template>
                        </Column>

                        <Column field='employee.surName' header='Bordro Tarihi' :sortable='true' style='width:10rem'>
                            <template #body='slotProps'>
                                {{ formatDateWithoutDayName(slotProps.data.workStartDate) }} <br> {{ formatDateWithoutDayName(slotProps.data.workEndDate) }}
                            </template>
                        </Column>

                        <Column field='afterDayOffsWorkDays' header='Gün Sayısı' :sortable='true' style='width:7rem'>
                            <template #body='slotProps'>
                                {{ slotProps.data.afterDayOffsWorkDays }} Gün
                            </template>
                        </Column>

                        <Column field='dayOff' header='Ücretsiz İzin' :sortable='true' style='width:7rem'>
                            <template #body='slotProps'>
                                {{ slotProps.data.dayOff }} Gün
                            </template>
                        </Column>

                        <Column field='dayOff' header='Ücretsiz İzin Tutarı' :sortable='true' style='width:8rem'>
                            <template #body='slotProps'>
                                - {{ formatPrice(slotProps.data.totalDayOffPrice) }} &#8378;
                            </template>
                        </Column>

                        <Column field='overtimeHours' header='Mesai Saati' :sortable='true' style='width:7rem'>
                            <template #body='slotProps'>
                                {{ slotProps.data.totalOvertimeHours }} Saat
                            </template>
                        </Column>

                        <Column field='normalOvertimeTotalPrice' header='Mesai Ücreti' :sortable='true' style='width:8rem'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.totalOverTimePrice) }} &#8378;
                            </template>
                        </Column>

                        <Column field='allowancePrice' header='Avans' :sortable='true' style='width:8rem'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.allowancePrice) }} &#8378;
                            </template>
                        </Column>

                        <Column field='officialAllowancePrice' header='Resmi Avans' :sortable='true' style='width:7rem'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.officialAllowancePrice) }} &#8378;
                            </template>
                        </Column>
                        <Column field='unOfficialAllowancePrice' header='Diğer Avans' :sortable='true' style='width:7rem'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.unOfficialAllowancePrice) }} &#8378;
                            </template>
                        </Column>
                        <Column field='totalBonus' header='Ödenen Prim' :sortable='false'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.totalBonus) }}
                            </template>
                        </Column>
                        <Column field='officialTotalPrice' header='Maaş' :sortable='true' style='width:8rem'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.officialTotalPrice) }} &#8378;
                            </template>
                        </Column>

                        <Column field='unOfficialTotalPrice' header='Diğer' :sortable='true' style='width:8rem'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.unOfficialTotalPrice) }} &#8378;
                            </template>
                        </Column>

                        <Column field='paymentTotal' header='Maaş Toplam' :sortable='true' style='width:8rem'>
                            <template #body='slotProps'>
                                {{ formatPrice(slotProps.data.paymentTotal) }} &#8378;
                            </template>
                        </Column>

                        <Column field='bankName' header='Banka / IBAN' :sortable='true' style='width:15rem'>
                            <template #body='slotProps'>
                                {{ slotProps.data.bankName }}
                                <br>
                                {{ slotProps.data.iban }}
                            </template>
                        </Column>

                        <Column field='isOfficialPaymentApproved' header='Maaş Ödeme' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                            <template #body='{data}'>
                                <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isOfficialPaymentApproved, 'text-pink-500 pi-times-circle': !data.isOfficialPaymentApproved}"></i>
                            </template>
                            <template #filter={filterModel}>
                                <TriStateCheckbox v-model='filterModel.value' />
                            </template>
                        </Column>

                        <Column field='isUnOfficialPaymentApproved' header='Diğer Ödeme' dataType='boolean' bodyClass='text-center' style='width:3rem'>
                            <template #body='{data}'>
                                <i class='pi' style='font-size:2rem;' :class="{'text-green-500 pi-check-circle': data.isUnOfficialPaymentApproved, 'text-pink-500 pi-times-circle': !data.isUnOfficialPaymentApproved}" @click='unOfficialPaymentUpdate(data.id, !data.isUnOfficialPaymentApproved)'></i>
                            </template>
                            <template #filter={filterModel}>
                                <TriStateCheckbox v-model='filterModel.value' />
                            </template>
                        </Column>

                    </DataTable>


                </div>

            </div>

        </div>


    </div>
</template>

<script>



import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import {
    getEmployee, getProjectEmployee,

} from '../common/commonFunctions';
import moment from 'moment';
import { checkActiveProjectIdIsValid, getActiveProjectId } from '../common/commonConstantFunctions';
import { FilterMatchMode } from 'primevue/api';
import EmployeeSalaryService from '../../services/employeeSalaryService';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _employeeSalaryService : null,
    created() {
        this._employeeService = new EmployeeService();
        this._employeeSalaryService = new EmployeeSalaryService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
        this.projectId = getActiveProjectId();
        this.initFilters();
    },
    async mounted() {
        this.dataItem = await getEmployee(this.employeeId);
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        await this.refreshSalaries();
    },

    data() {
        return {
            year: 1900,
            month: 1,
            employeeId: 0,
            projectEmployeeId:0,
            dataList:[],
            loadingIndicator:false,
            dataItem: {
                projectId: 0,
                paymentDate: '',
                managerUserId: 0,
            },
            visibleLeft: false,
        };
    },
    methods: {
        async refreshSalaries() {
            this.loadingIndicator = true;
            let getResponse = await this._employeeSalaryService.getAllEmployeeSalaries(this.projectId, this.employeeId);
            if (getResponse.isSuccess) {
                this.dataList = getResponse.data;
                this.loadingIndicator = false;
            }
        },

        initFilters() {
            this.filters = {
                'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
        formatDate(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY - dddd');
        },
        formatDateYA(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('YYYY - MM');
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        },
        formatDateWithoutDayName(value) {
            moment.locale('tr');
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },

    },
};

</script>


<style scoped>

</style>
